var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, inject, provide, ref, watch } from 'vue';
import api from '../../app/api-generated/qmForm';
import callcodeApi from '../../app/api-generated/callCodes';
import { useDnxServerResponse, T } from '@dnx/core';
import { useRouteQuery } from '@vueuse/router';
export const provideQuestionsData = () => {
    const selectedFormId = useRouteQuery('form');
    const formData = ref();
    const callCodes = ref([]);
    const tour = useRouteQuery('tour');
    const fetchForm = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (id === undefined)
            return;
        const response = yield api.get(id);
        if (!response.success)
            return;
        formData.value = response.value;
        return response.value;
    });
    const forms = useDnxServerResponse(api.list, []);
    forms.fetch();
    const deleteForm = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield api.delete(id);
        if (!response.success)
            return;
        forms.fetch();
        formData.value = undefined;
        selectedFormId.value = undefined;
        return response.value;
    });
    // delete endpoint isn't ready yet
    const deleteQuestion = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield api.delete(id);
        if (!response.success)
            return;
        return response.value;
    });
    // endpoint isn't ready yet
    const saveFormData = (formData, id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield api.update(formData, id);
        if (!response.success)
            return response;
        forms.fetch();
        return response;
    });
    const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
        const formToFetch = selectedFormId.value == 'default' ? null : selectedFormId.value;
        yield fetchForm(formToFetch);
    });
    const isDefaultForm = computed(() => !selectedFormId.value || selectedFormId.value === 'default');
    watch(selectedFormId, id => {
        if (!id) {
            return;
        }
        fetch();
    }, { immediate: true });
    const formTitle = computed(() => { var _a, _b, _c; return ((_a = formData.value) === null || _a === void 0 ? void 0 : _a.id) ? (_b = formData.value) === null || _b === void 0 ? void 0 : _b.name : T((_c = formData.value) === null || _c === void 0 ? void 0 : _c.name); });
    const fetchCallcodeOptions = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield callcodeApi.getCallCodes();
        if (!response.success)
            return;
        callCodes.value = response.value;
    });
    const state = {
        formData,
        fetchForm,
        fetchCallcodeOptions,
        callCodes,
        deleteForm,
        selectedFormId,
        isDefaultForm,
        forms,
        deleteQuestion,
        tour,
        saveFormData,
        formTitle,
        fetch,
    };
    provide('questions', state);
    return state;
};
export const injectQuestionsData = () => {
    const state = inject('questions');
    if (!state) {
        throw 'No available data';
    }
    return state;
};
