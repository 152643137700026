import { T } from '@dnx/core';
const tourT = (translationKey) => {
    return T(translationKey).split('\n').join('<br/><br/>');
};
export const getOverviewTourSteps = () => [
    {
        id: 'forms',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_FORMS_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_FORMS_TEXT'),
        },
    },
    {
        id: 'forms-add-new',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_FORMS_ADD_NEW_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_FORMS_ADD_NEW_TEXT'),
        },
    },
    {
        id: 'category-overview',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_CATEGORY_OVERVIEW_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_CATEGORY_OVERVIEW_TEXT'),
        },
    },
    {
        id: 'question-overview',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_OVERVIEW_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_OVERVIEW_TEXT'),
        },
    },
    {
        id: 'edit-button',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_EDIT_BUTTON_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_EDIT_BUTTON_TEXT'),
        },
    },
    {
        id: 'category-switch',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_CATEGORY_SWITCH_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_CATEGORY_SWITCH_TEXT'),
        },
    },
    {
        id: 'question-switch',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_SWITCH_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_SWITCH_TEXT'),
        },
    },
    {
        id: 'category-add',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_CATEGORY_ADD_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_CATEGORY_ADD_TEXT'),
        },
    },
    ...getCustomQuestionsTourSteps(),
    {
        id: 'campaigns',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_CAMPAIGNS_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_CAMPAIGNS_TEXT'),
        },
    },
];
export const getCustomQuestionsTourSteps = () => [
    {
        id: 'question-add-button',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_BUTTON_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_BUTTON_TEXT'),
        },
    },
    {
        id: 'question-add-overview',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_OVERVIEW_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_OVERVIEW_TEXT'),
        },
    },
    {
        id: 'question-add-question',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_QUESTION_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_QUESTION_TEXT'),
        },
    },
    {
        id: 'question-add-category',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_CATEGORY_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_CATEGORY_TEXT'),
        },
    },
    {
        id: 'question-add-location',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_LOCATION_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_LOCATION_TEXT'),
        },
    },
    {
        id: 'question-add-result',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_RESULT_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_RESULT_TEXT'),
        },
    },
    {
        id: 'question-add-exclusion',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_EXCLUSION_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_EXCLUSION_TEXT'),
        },
    },
    {
        id: 'question-add-negative',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_NEGATIVE_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_NEGATIVE_TEXT'),
        },
    },
    {
        id: 'question-add-keyword-group',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_GROUP_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_GROUP_TEXT'),
        },
    },
    {
        id: 'question-add-keyword-add',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_ADD_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_ADD_TEXT'),
        },
    },
    {
        id: 'question-add-keyword-speaker',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_SPEAKER_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_SPEAKER_TEXT'),
        },
    },
    {
        id: 'question-add-keyword-conditionals',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_CONDITIONALS_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_ADD_KEYWORD_CONDITIONALS_TEXT'),
        },
    },
    {
        id: 'question-confirm',
        popover: {
            title: tourT('MANGL_QMFORM_TOUR_QUESTION_CONFIRM_TITLE'),
            description: tourT('MANGL_QMFORM_TOUR_QUESTION_CONFIRM_TEXT'),
        },
    },
];
